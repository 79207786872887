import React from 'react';

import config from '../../config';

export default function Footer({ onClose, isVisible }) {
  return (
    <footer id="footer" className={`panel ${isVisible ? 'active' : ''}`}>
      <div className="inner split">
        <div>
          <section>
            <h2>More about me</h2>
            <p>
              Stephen Shirak
              <br />
              Professional digital illustration for packaging branding and
              design. Very quick professional service - Reasonable pricing.
              Satisfied customers include: PepsiCo, Gillette, Victoria’s Secret,
              Avon, Laura Mercier, Dole, Glad Products, Johnson & Johnson, Dr
              Scholl’s, Tribe Hummus, and many more.
              <br />
              Contact me for more samples.
            </p>
          </section>
          <section>
            <h2>Email: stephenshirak1@gmail.com</h2>
            <ul className="icons">
              {config.socialLinks.map(social => {
                const { icon, name, url } = social;
                return (
                  <li key={url}>
                    <a href={url} className={`icon ${icon}`}>
                      <span className="label">{name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
        <div>
          <section>
            <h2>Get in touch</h2>
            <form
              name="contact"
              method="post"
              action="#"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <div className="fields">
                <div className="field half">
                  <input type="text" name="name" id="name" placeholder="Name" />
                </div>
                <div className="field half">
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact"
                    data-netlify-recaptcha="true"
                  />
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                  />
                </div>
                <div className="field">
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    placeholder="Message"
                  />
                </div>
              </div>
              <ul className="actions">
                <li>
                  <input type="submit" value="Send" className="primary" />
                </li>
                <li>
                  <input type="reset" value="Reset" />
                </li>
              </ul>
            </form>
          </section>
        </div>
      </div>
      <div
        className="closer"
        onClick={e => {
          e.preventDefault();
          onClose();
        }}
      />
    </footer>
  );
}
