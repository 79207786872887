module.exports = {
  siteTitle: 'Stephen Shirak Art', // <title>
  manifestName: '',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Stephen Shirak',
  subHeading: 'Illustrator',
  // social
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:stephenshirak1@gmail.com',
    },
  ],
};
